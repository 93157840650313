import axios from "axios";
import get from "lodash/get";

import { BASE_API_URL } from "utils/app-constants";
import { ErrorSubject } from "shared/ErrorSubject";
import Auth from "shared/Auth";

const instance = axios.create({
  baseURL: BASE_API_URL
});

/**
 * Intercept every request and append the authorization header
 */
instance.interceptors.request.use(
  config => {
    const token = Auth.getToken();

    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }

    ErrorSubject.next({ connectivity: true });

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * Intercept every response to see if the token has expired and requires a refresh
 */
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    let errorSubject = JSON.parse(JSON.stringify(error));

    // Display no connection

    if (!get(errorSubject, "response") && !get(errorSubject, "message")) {
      ErrorSubject.next({ connectivity: false, errorSubject });
    }

    if (get(error, "response.data.msg") === "Token has expired") {
      Auth.logout({
        message: "Your session has expired. Please login again.",
        type: "warning"
      });
    }

    return Promise.reject(error);
  }
);

export default instance;
