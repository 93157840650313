import React, { Component } from "react";

import get from "lodash/get";
import map from "lodash/map";
import range from "lodash/range";
import find from "lodash/find";
import lowerCase from "lodash/lowerCase";
import Input from "ui/Input";

class FieldProcessor extends Component {
  constructor() {
    super();

    this.state = {
      selectValue: "",
      newRowValue: 5,
      mandatoryText: "Please answer",
      addBtn: "Add Row",
      selectionText: {
        start: "Select up to ",
        end: "domaines"
      }
    };
  }

  processQuestion = (questionDef, titleKey, valueKey) => {
    let baseQuestion = questionDef.map((questionItem, index) => (
      <div key={index}>
        <p className="question-list__item__question">
          {get(questionItem, titleKey)}
        </p>
        {
          <FieldProcessor
            titleKey={titleKey}
            valueKey={valueKey}
            question={questionItem}
            key={index}
            responses={this.props.responses}
          />
        }
      </div>
    ));

    return baseQuestion;
  };

  mapControlTypes = (field, index, response = "", titleKey, valueKey) => {
    let fieldType;

    const { control, type, choices, question_id, id } = field;

    switch (control) {
      case "input":
        type === "dropdown"
          ? (fieldType = (
              <div>
                <i className="field-answer">
                  {typeof response !== "object" && response.toString()}
                </i>
              </div>
            ))
          : (fieldType = (
              <div key={index}>
                <Input
                  itemValue={field[valueKey]}
                  options={choices || []}
                  title={field[titleKey] || field[valueKey]}
                  key={index}
                  fieldId={id}
                  nameVal={question_id}
                  nature={type}
                  response={response}
                />
              </div>
            ));
        break;
      case "date":
        fieldType = response;
        break;
      default:
        break;
    }

    return fieldType;
  };

  render() {
    const { question, responses, titleKey, valueKey } = this.props;

    const { fields, additional_field, id } = question;

    const specialFieldsType =
      get(find(fields, ["type", "checkbox"]), "type", "") ||
      get(find(fields, ["type", "radio"]), "type", "") ||
      get(find(fields, ["type", "date"]), "type", "") ||
      get(find(fields, ["type", "table"]), "type", "");

    let specialFields,
      currentKey,
      defaultValue,
      valueTriggered,
      valueInResponse;

    currentKey = id;

    switch (specialFieldsType) {
      case "checkbox":
        defaultValue = map(get(responses, currentKey), value => value);

        specialFields = (
          <div>
            <i className="field-answer">{defaultValue.join(", ")}</i>
          </div>
        );
        break;
      case "radio":
        defaultValue = map(get(responses, currentKey), value => value)[0];

        valueInResponse =
          lowerCase(defaultValue) ===
            lowerCase(get(additional_field, `trigger_${valueKey}`)) &&
          !valueTriggered;

        specialFields = (
          <div>
            <i className="field-answer">{defaultValue}</i>
            {get(additional_field, "questions") &&
              valueInResponse
              ? this.processQuestion(
                  get(additional_field, "questions"),
                  titleKey,
                  valueKey
                )
              : null}
          </div>
        );
        break;
      case "date":
        let fieldId = get(find(fields, ["type", "date"]), "id");

        defaultValue = get(
          get(responses, currentKey, ""),
          fieldId,
          ""
        ).toString();

        specialFields = (
          <div>
            <i className="field-answer">{defaultValue}</i>
          </div>
        );
        break;
      case "table":
        let table = fields[0];

        let tableColumns = get(table, "columns", []);

        let tableRows = get(table, "rows", this.state.newRowValue);

        let rowCount = range(0, tableRows, 1);

        defaultValue = get(responses, currentKey, []);

        let inputRows = rowCount.map((row, rowNumber) => {
          let columns = tableColumns.map(fieldDef => {
            let inputKey = `${currentKey}[${rowNumber}].${get(fieldDef, "id")}`;

            let columnValue =
              (defaultValue.length &&
                defaultValue[rowNumber][get(fieldDef, "id")]) ||
              "";

            return (
              <td key={inputKey}>
                <i className="field-answer">{columnValue}</i>
              </td>
            );
          });

          return <tr key={rowNumber}>{columns}</tr>;
        });

        let baseWidth = 250;

        let headers = tableColumns.map((column, i) => {
          return (
            <th style={{ width: `${baseWidth}px` }} key={i}>
              {get(column, titleKey, "")}
            </th>
          );
        });

        specialFields = (
          <div>
            <div className="table-wrapper">
              <table
                style={{ width: `${baseWidth * headers.length}px` }}
                className="table"
              >
                <thead>
                  <tr>{headers}</tr>
                </thead>
                <tbody>{inputRows}</tbody>
              </table>
            </div>
            {headers.length > 3 ? (
              <p className="small">
                <i>Scroll right to view more content.</i>
              </p>
            ) : null}
          </div>
        );
        break;
      default:
        let normalFields = fields.map((fieldDef, index) => {
          defaultValue =
            get(get(responses, currentKey, ""), get(fieldDef, "id")) ||
            get(responses, currentKey, "");

          let field = (
            <div key={index}>
              {this.mapControlTypes(
                fieldDef,
                index,
                defaultValue,
                titleKey,
                valueKey
              )}
            </div>
          );

          return field;
        });

        specialFields = <div>{normalFields}</div>;
        break;
    }

    return (
      <div className="question-list__item__question-inputs">
        {specialFields}
      </div>
    );
  }
}

export default FieldProcessor;
