const accessMethods = {
  saveList: (key, list) => {
    localStorage.setItem(key, JSON.stringify(list));
  },
  getList: (key) => {
    return JSON.parse(localStorage.getItem(key)) || [];
  },
  saveItem: (key, value) => {
    localStorage.setItem(key, value);
  },
  getItem: (key) => {
    return localStorage.getItem(key);
  },
  removeItem: (key) => {
    return localStorage.removeItem(key);
  }
}

export default accessMethods;