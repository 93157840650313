import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Card } from "antd";
import get from "lodash/get";
import find from "lodash/find";
import axios from "axios";

import { SURVEY_STAGE_KEY, CDR_STAGES } from "utils/app-constants";
import localStorage from "utils/localStorage";
import PageTitle from "shared/PageTitle";
import axiosClient from "utils/axios";
import BlankState from "shared/BlankState";
import Responses from "./Responses";
import CDRScores from "./CDRScores";
import Auth from "shared/Auth";
import Roles from "./Roles";
import ProfileInfo from "./ProfileInfo";
import { ReloadPageSubject } from "shared/ReloadPageSubject";
import Verification from "./Verification";

const cdrMenus = localStorage.getList(CDR_STAGES).map(cdrStage => ({
  key: get(cdrStage, 'id'),
  tab: get(cdrStage, 'title'),
}));

class Detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetail: null,
      loading: false,
      contentKey: get(localStorage.getList(CDR_STAGES)[0], 'id'),
      titleKey: "title",
      valueKey: "value"
    };

    this.axiosHandler = axios.CancelToken.source();
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.axiosHandler.cancel("Unmounting component");
  }

  loadData = () => {
    this.setState({ loading: true });

    let userId = this.props.match.params.id;

    axiosClient
      .get(`/user/${userId}`, { cancelToken: this.axiosHandler.token })
      .then(response => {
        let userDetail = get(response.data, "returned_resultset[0]", {});

        const titleMap = {
          en_US: "title",
          fr_FR: "title_fr"
        };

        const valueMap = {
          en_US: "value",
          fr_FR: "value_fr"
        };

        const currentLanguage = get(userDetail, "locale");

        const titleKey = get(titleMap, currentLanguage);

        const valueKey = get(valueMap, currentLanguage);

        this.setState({ titleKey, valueKey, userDetail});

        let stages = localStorage.getList(CDR_STAGES).map(cdrStage => {
          return axiosClient
            .get(`/stage/${get(cdrStage, 'id')}`, {
              cancelToken: this.axiosHandler.token
            })
            .then(response => {
              localStorage.saveList(
                `${get(cdrStage, 'id')}_FORMS`,
                get(response.data, "returned_resultset[0].forms", [])
              );

              localStorage.saveItem(
                `${get(cdrStage, 'id')}_TITLE`,
                get(response.data, "returned_resultset[0].title", [])
              );

              return {
                cdrStageId: get(cdrStage, 'id'),
                titleKey,
                valueKey,
              }
            });
        });

        Promise.all(stages)
          .then((responses) => {
            responses.forEach(responseItem => {
              this.setState({
                [get(responseItem, 'cdrStageId')]: responseItem,
                loading: false,
              });
            })
          }).catch(() => {
            this.setState({ loading: false });
          });

      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  onTabChange = (key, type) => {
    this.setState({ contentKey: key });
  };

  setStageName = () => {
    return localStorage.getItem(SURVEY_STAGE_KEY);
  };

  setCountryName = () => {
    let country = find(Auth.getCountries(), [
      "Code",
      get(this.state.userDetail, "country_code")
    ]);

    return get(country, "Name");
  };

  updateUser = userDetail => {
    this.setState({ userDetail });
  };

  refreshReponses = () => {
    ReloadPageSubject.next({ page: "applicant-responses" });
  };

  render() {
    const { loading, userDetail, titleKey, valueKey } = this.state;
    
    let baseContentMap = {
      score: <CDRScores {...this.props} userDetail={userDetail} />,
      userRole: <Roles userDetail={userDetail} updateUser={this.updateUser} />,
      verification: (
        <Verification titleKey={titleKey} userDetail={userDetail} />
      ),
      profileInfo: (
        <ProfileInfo
          userDetail={userDetail}
          updateUser={this.updateUser}
          refreshData={this.loadData}
        />
      )
    };

    localStorage.getList(CDR_STAGES).forEach(cdrStage => {
      baseContentMap = {
        [get(cdrStage, 'id')]: (
          <Responses
            key={get(cdrStage, 'id')}
            {...this.props}
            cdrStageId={get(cdrStage, 'id')}
            userDetail={userDetail}
            titleKey={titleKey}
            valueKey={valueKey}
          />
        ),
        ...baseContentMap,
      }
    });

    const tabListNoTitle = [
      ...cdrMenus,
      {
        key: "score",
        tab: "CDR Score"
      },
      {
        key: "userRole",
        tab: "Assign Role"
      },
      {
        key: "verification",
        tab: "Verify"
      },
      {
        key: "profileInfo",
        tab: "Profile Info"
      }
    ];

    const languageMap = {
      en_US: "English",
      fr_FR: "French"
    };

    if (loading) return <BlankState type="detail-view" />;

    return (
      <div className="applicant-detail">
        <PageTitle title={`Applicant Profile - ${get(userDetail, "names")}`} />
        <Card
          title="Applicant Profile"
          extra={
            <div className="action-bar">
              <span
                className="action-item primary-orange"
                onClick={this.refreshReponses}
              >
                Reload Responses
              </span>
              <Link className="action-item" to="/applicants">
                Back to Applicants
              </Link>
            </div>
          }
        >
          <div className="row detail-info">
            <div className="col-2">Name:</div>
            <div className="col-4 text-left">{get(userDetail, "names")}</div>
            <div className="col-2">Email:</div>
            <div className="col-4 text-left">{get(userDetail, "email")}</div>
          </div>
          <div className="row detail-info">
            <div className="col-2">Country:</div>
            <div className="col-4 text-left">{this.setCountryName()}</div>
            <div className="col-2">Current Stage:</div>
            <div className="col-4 text-left">{this.setStageName()}</div>
          </div>
          <div className="row detail-info">
            <div className="col-2">Role:</div>
            <div className="col-4 text-left">
              {get(userDetail, "role.name", "N/A")}
            </div>
            <div className="col-2">Language:</div>
            <div className="col-4 text-left">
              {get(languageMap, get(userDetail, "locale", "N/A"))}
            </div>
          </div>
        </Card>
        <Card
          tabList={tabListNoTitle}
          style={{ marginTop: "0.75rem" }}
          onTabChange={key => {
            this.onTabChange(key, "contentKey");
          }}
          className="tab-body"
        >
          {get(baseContentMap, this.state.contentKey)}
        </Card>
      </div>
    );
  }
}

export default Detail;
