import React, { Component } from "react";

import get from "lodash/get";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import find from "lodash/find";
import axios from "axios";

import axiosClient from "utils/axios";
import { CDR_STAGES } from "utils/app-constants";
import localStorage from "utils/localStorage";

class CDRScores extends Component {
  constructor() {
    super();

    this.state = {
      cdrScores: [],
      loading: false
    };

    this.axiosHandler = axios.CancelToken.source();
  }

  componentDidMount() {
    this.retrieveCDR();
  }

  retrieveCDR = () => {
    this.setState({ loading: true });

    let payload = {
      user_id: get(this.props.userDetail, "id"),
      dry_run: true
    };

    let currentStage = sortBy(localStorage.getList(CDR_STAGES), [
      "ordering"
    ])[0];

    axiosClient
      .post(`/stage/${get(currentStage, 'id')}/complete`, payload, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        let cdrScores = map(
          get(response.data, "cdr_scores"),
          (score, value) => score[0]
        );

        this.setState({ cdrScores, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  displayScore = sectionTitle => {
    let currentScore = find(this.state.cdrScores, item => {
      let formTitle = get(item, "form_title");

      return formTitle.match(sectionTitle);
    });

    return currentScore
      ? `${get(currentScore, "score", "N/A")} (${get(
          currentScore,
          "points",
          "N/A"
        )})`
      : "N/A";
  };

  render() {
    if (this.state.loading) {
      return (
        <p className="pre-loader sub-head text-center">Loading CDR Scores...</p>
      );
    }

    if (!this.state.cdrScores.length) {
      return (
        <p className="pre-loader sub-head text-center">
          {get(this.props.userDetail, "names")} has not began filling the
          application.
        </p>
      );
    }

    return (
      <div className="cdr-banner">
        <p className="explanation">This is the applicants performance</p>
        <br />
        <table className="cdr-table">
          <thead>
            <tr>
              <th>
                <p className="small bold near-black uppercase">
                  Capacity Element
                </p>
              </th>
              <th>
                <p className="small bold near-black uppercase">
                  What we are trying to assess
                </p>
              </th>
              <th>
                <p className="small bold near-black uppercase">
                  How They performed
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p className="small bold near-black uppercase">
                  Strategic Ability and Adaptability
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  This indicator assesses clarity of strategy, priorities;
                  readiness and ability to pursue them; ability to understand
                  and adapt to changing circumstances and opportunities. It
                  examines the framework that you have put in place to fulfill
                  your mission, e.g. goal setting, measuring success and
                  appropriate resource allocation.
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  {this.displayScore("Strategic Ability")}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="small bold near-black uppercase">
                  Leadership and Governance
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  This indicator evaluates the capacity of your leadership team
                  to guide your organization’s work through strategy, vision and
                  inspiration. It also analyzes the use of governance
                  structures, which maintain your organization’s accountability,
                  reputation and integrity.
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  {this.displayScore("Leadership and Governance")}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="small bold near-black uppercase">
                  Financial Health & Organizational Management
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  This indicator reviews your organization’s resources and
                  income, professionalism of financial management and your
                  financial and reporting systems. This review is vital to
                  verifying the long-term viability and success of your
                  organization based on sound financial management.
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  {this.displayScore("Financial Health")}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="small bold near-black uppercase">
                  Human Resources and Staff Development
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  This indicator analyzes the staff size, quality, and skill
                  sets to address mission and drive performance. It also looks
                  at human resource management, which plays a strategic role in
                  managing people and the overall culture and direction of your
                  organization.
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  {this.displayScore("Human Resources")}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="small bold near-black uppercase">
                  Communications
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  This indicator assesses your organization’s ability to
                  identify, reach and influence audiences in line with your
                  mission. A communication strategy ensures that you maintain
                  consistent messaging about your work and develop relevant and
                  timely material to help you achieve your organizational
                  objectives.
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  {this.displayScore("Communications")}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="small bold near-black uppercase">
                  Monitoring and Evaluation
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  This indicator evaluates whether or not your organization uses
                  performance indicators and uses the lessons learned for
                  continuous improvement of your organization.
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  {this.displayScore("Monitoring")}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="small bold near-black uppercase">
                  Partnerships and Alliances
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  This indicator measures your organization’s capacity and
                  reputation for creating value with others. It evaluates your
                  organization’s use of networks and your willingness to
                  collaborate with partners to further organizational goals.
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  {this.displayScore("Partnerships")}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="small bold near-black uppercase">Operations</p>
              </td>
              <td>
                <p className="normal__smaller">
                  This indicator evaluates whether your organization has
                  adequate tools, resources, and structures to effectively
                  manage operational risks and implement programs.
                </p>
              </td>
              <td>
                <p className="normal__smaller">
                  {this.displayScore("Operations")}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default CDRScores;
