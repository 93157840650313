import React, { Component } from "react";
import WrappedProfileInfoForm from "./ProfileInfoForm";

class ProfileInfo extends Component {
  render() {
    return (
      <div className="cdr-banner">
        <WrappedProfileInfoForm {...this.props} />
      </div>
    );
  }
}

export default ProfileInfo;
