import React, { Component } from "react";

import get from "lodash/get";

class Input extends Component {
  constructor() {
    super();

    this.state = {
      hideFile: true,
      viewFile: "View Current File",
      uploadPrompt: "Upload New File"
    };
  }

  render() {
    let inputField;

    const { nature, response, title } = this.props;

    // Monkey patch to avoid nested objects making it through.
    let getKeys = typeof response === "object" && Object.keys(response || {});

    let finalResponse = getKeys ? get(response || {}, getKeys[0]) : response;

    switch (nature) {
      case "file":
        inputField = response.length ? (
          <div className="input-file-upload">
            <a
              href={response}
              className={`file-link ${this.state.hideFile ? "" : "hidden"}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="field-answer">{this.state.viewFile}</i>
            </a>
          </div>
        ) : null;
        break;
      case "radio":
        inputField = (
          <div className="flex align-items-center" tabIndex="1">
            <i className="field-answer">{finalResponse}</i>
          </div>
        );
        break;
      case "checkbox":
        inputField = (
          <div className="flex align-items-center">
            <i className="field-answer">{finalResponse}</i>
          </div>
        );
        break;
      case "textarea":
        inputField = (
          <div className="input-field">
            <i className="field-answer">{finalResponse}</i>
          </div>
        );
        break;
      default:
        inputField = (
          <div className="input-field">
            {title ? (
              <label>
                <span type="small" modifiers="inline">
                  {title}
                </span>
              </label>
            ) : null}
            <i className="field-answer">{finalResponse}</i>
          </div>
        );
        break;
    }

    return inputField;
  }
}

export default Input;
