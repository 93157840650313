import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

const FormStatus = props => {
  const { formInfo, loading } = props;

  return <div className="">
      <div className={loading ? 'text-center alert loading primary-black' : 'hidden'}>
        <p className="primary-green explanation">Submitting Info ...</p>
      </div>
      <div className={get(formInfo, 'message') ? `alert ${get(formInfo, 'type')} pure-white` : 'hidden'}>
        <p className="explanation">
          {get(formInfo, 'message')}
        </p>
      </div>
    </div>;
};

FormStatus.propTypes = {
  formInfo: PropTypes.object,
  loading: PropTypes.bool
};

export default FormStatus;