import React, { Component } from "react";

import { Form, Button, Input, Upload, Icon } from "antd";
import axios from "axios";
import get from "lodash/get";
import find from "lodash/find";
import filter from "lodash/filter";
import capitalize from "lodash/capitalize";
import replace from "lodash/replace";

import axiosClient from "utils/axios";
import ErrorParser from "utils/ErrorParser";
import FormStatus from "shared/FormStatus";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

class ProfileInfoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formInfo: {},
      attributes: [],
      loading: false,
      fileList: [],
      userDetail: this.props.userDetail,
      editMode: get(this.props.userDetail, "attributes", []).length ? true : false
    };

    this.authListener = null;
    this.axiosHandler = axios.CancelToken.source();
  }

  componentDidMount() {
    axiosClient
      .get(`/attribute`, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        this.setState({ attributes: get(response.data, "returned_resultset") });
      })
      .catch(() => {
        this.setState({ loading: false });
      });

    if (this.state.editMode) {
      let files = filter(get(this.state.userDetail, "attributes", []), [
        "attribute.type",
        "file"
      ]);

      this.setState({
        fileList: files.map(fileItem => {
          return {
            name: `${get(fileItem, "attribute.name")}`,
            url: `${get(fileItem, "value")}`,
            status: "done",
            uid: `${get(fileItem, "attribute.id")}`
          };
        })
      });
    }
  }

  componentWillUnmount() {
    this.axiosHandler.cancel("Unmounting component");
  }

  normFile = e => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList && e.fileList.splice(-1);
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.setState({
        errorMessage: "",
        loading: true,
        formInfo: {}
      });

      let attributeUpdates = [];

      this.state.attributes.forEach(attribute => {
        const formData = new FormData();

        if (!this.state.editMode) {
          formData.append("attribute_id", get(attribute, "id"));
        }

        if (get(attribute, "type") === "file") {
          formData.append(
            "value",
            get(this.state.fileList[0], "originFileObj")
          );
        } else {
          formData.append("value", values[get(attribute, "name")]);
        }

        let config = {
          method: this.state.editMode ? "PUT" : "POST",
          url: this.state.editMode
            ? `/user/${get(this.state.userDetail, "id")}/attribute/${get(
                attribute,
                "id"
              )}`
            : `/user/${get(this.state.userDetail, "id")}/attribute`,
          data: formData
        };

        let updateCall = axiosClient(config);

        attributeUpdates.push(updateCall);

        if (!get(this.state.fileList[0], "originFileObj") && get(attribute, "type") === "file") {
          attributeUpdates.pop()
        }
      });

      Promise.all(attributeUpdates)
        .then(responses => {
          axiosClient
            .get(`/user/${get(this.state.userDetail, "id")}`, {
              cancelToken: this.axiosHandler.token
            })
            .then(response => {
              let userDetail = get(response.data, "returned_resultset[0]", {});

              let files = filter(get(userDetail, "attributes", []), [
                "attribute.type",
                "file"
              ]);

              this.setState({
                userDetail,
                editMode: true,
                attributes: get(userDetail, "attributes", []).map(i =>
                  get(i, "attribute")
                ),
                fileList: files.map(fileItem => {
                  return {
                    name: `${get(fileItem, "attribute.name")}`,
                    url: `${get(fileItem, "value")}`,
                    status: "done",
                    uid: `${get(fileItem, "attribute.id")}`
                  };
                })
              });

              this.props.updateUser(userDetail);
            })
            .catch(() => {
              this.setState({ loading: false });
            });
          this.setState({
            loading: false,
            formInfo: {
              message: "Profile Info Updated Successfully",
              type: "success"
            }
          });
        })
        .catch(error => {
          this.setState({ loading: false, formInfo: ErrorParser(error) });
        });
    });
  };

  getAttributeValue = attributeItem => {
    let fileItem = find(get(this.state.userDetail, "attributes", []), [
      "attribute_id",
      get(attributeItem, "id")
    ]);
    return get(fileItem, "value");
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { loading, formInfo, attributes, fileList, editMode } = this.state;

    const uploadProps = {
      onChange: info => {
        let fileList = info.fileList;

        // 1. Limit the number of uploaded files
        // Only to show one recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-1);

        this.setState({ fileList });
      },
      onRemove: file => {
        this.setState(state => {
          return {
            fileList: file
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: file
        }));
        return false;
      }
    };

    const formFields = attributes.map((attributeItem, index) => {
      return get(attributeItem, "type") === "file" ? (
        <Form.Item key={index} label={`CSO ${get(attributeItem, "name")}`}>
          {getFieldDecorator(get(attributeItem, "name"), {
            initialValue: fileList,
            valuePropName: "fileList",
            getValueFromEvent: this.normFile
          })(
            <Upload
              {...uploadProps}
              fileList={fileList}
              name={get(attributeItem, "name")}
              listType="picture"
            >
              <Button>
                <Icon type="upload" /> Click to upload Logo
              </Button>
            </Upload>
          )}
        </Form.Item>
      ) : (
        <Form.Item key={index} label={`${capitalize(replace(get(attributeItem, "name"), /_/g, ' '))}`}>
          {getFieldDecorator(`${get(attributeItem, "name")}`, {
            initialValue: editMode ? this.getAttributeValue(attributeItem) : ""
          })(<TextArea rows={4} placeholder='Enter info' />)}
        </Form.Item>
      );
    });

    return (
      <Form onSubmit={this.handleSubmit} className="profile-info-form">
        <FormStatus formInfo={formInfo} loading={loading} />
        {formFields}
        {attributes.length ? (
          <FormItem>
            <Button type="primary" htmlType="submit">
              Update Details
            </Button>
          </FormItem>
        ) : (
          <p className="pre-loader sub-head text-center">Loading Fields...</p>
        )}
      </Form>
    );
  }
}

const WrappedProfileInfoForm = Form.create()(ProfileInfoForm);

export default WrappedProfileInfoForm;
