import React, { Component } from 'react';
import get from "lodash/get";

import axios from "utils/axios";
import { ErrorSubject } from "shared/ErrorSubject";

class Connectivity extends Component {
  constructor(){
    super();
    
    this.state = {
      connectivity: true,
      loading: false,
      errorSubject: {}
    }

    this.errorSubject = null;
  }

  componentDidMount(){
    this.errorSubject = ErrorSubject.subscribe(error => {
      this.setState({ 
        connectivity: error.connectivity, 
        errorSubject: error.errorSubject,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    if (this.errorSubject !== null) {
      this.errorSubject.unsubscribe();
      this.errorSubject = null;
    }
  }

  tryReconnecting = () => {
    this.setState({ loading: true });

    let method = get(this.state.errorSubject, "config.method");
    let data = method === "post" ? JSON.parse(get(this.state.errorSubject, "config.data")) : {};

    axios({
        method,
        url: get(this.state.errorSubject, "config.url"),
        data
      }) 
  }

  render(){
    let hidden = (this.state.connectivity || this.state.loading) ?  'hidden' : '';

    return <div className="connectivity-box">
        <h3 className={`bg-alert-red connectivity-alert ${hidden}`}>
          Connectivity Issue or Unknown Error from the Server
        </h3>
      </div>;
  }
};

export default Connectivity;