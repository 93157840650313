import React, { Component } from "react";

import { Form, Input, Icon, Button } from "antd";
import axios from "axios";
import get from "lodash/get";

import axiosClient from "utils/axios";
import {
  PROFILE_KEY,
  COUNTRIES_KEY,
  VERIFIABLE_FIELDS_KEY,
  CDR_STAGES
} from "utils/app-constants";
import { AuthSubject } from "shared/AuthSubject";
import ErrorParser from "utils/ErrorParser";
import FormStatus from "shared/FormStatus";
import localStorage from "utils/localStorage";

const FormItem = Form.Item;

class LoginForm extends Component {
  constructor() {
    super();

    this.state = {
      formInfo: {},
      loading: false
    };

    this.authListener = null;
    this.axiosHandler = axios.CancelToken.source();
  }

  componentDidMount() {
    this.authListener = AuthSubject.subscribe(newState => {
      this.setState({ formInfo: newState });
    });

    this.loadCountries();
  }

  loadCountries = () => {
    axiosClient
      .get("/country", {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        localStorage.saveList(COUNTRIES_KEY, response.data);
      });
  };

  componentWillUnmount() {
    if (this.authListener !== null) {
      this.authListener.unsubscribe();
      this.authListener = null;
    }

    this.axiosHandler.cancel("Unmounting component");
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.setState({
        errorMessage: "",
        loading: true,
        formInfo: {}
      });

      axiosClient
        .post("/auth/login", values)
        .then(response => {
          if (
            get(response.data, "user.role.name") === "ADMIN" ||
            get(response.data, "user.role.name") === "SUPPORT"
          ) {
            localStorage.saveList(PROFILE_KEY, response.data);

            let verifiableFields = axiosClient
              .get(`/verifiablefields`, {
                cancelToken: this.axiosHandler.token
              })
              .then(response => {
                return get(response.data, "returned_resultset", [])
              });
            
            let cdrStages = axiosClient
              .get(`/stage/cdr-able`, {
                cancelToken: this.axiosHandler.token
              })
              .then(response => {
                return get(response.data, "returned_resultset", [])
              });
              
            Promise.all([verifiableFields, cdrStages])
              .then((responses) => {
                localStorage.saveList(
                  VERIFIABLE_FIELDS_KEY,
                  responses[0]
                );

                localStorage.saveList(
                  CDR_STAGES,
                  responses[1]
                );

                AuthSubject.next({ profile: true });
              })
              .catch(error => {
                this.setState({ loading: false, formInfo: ErrorParser(error) });
              });

            return;
          }

          this.setState({
            loading: false,
            formInfo: {
              message: "You do not have permission to view the dashboard.",
              type: "error"
            }
          });
        })
        .catch(error => {
          this.setState({ loading: false, formInfo: ErrorParser(error) });
        });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { loading, formInfo } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <FormStatus formInfo={formInfo} loading={loading} />
        <FormItem>
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Please input your email" }]
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="email"
              placeholder="Email"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your Password" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </FormItem>
        <FormItem className="text-center">
          <Button type="primary" htmlType="submit" className="form-button">
            Log in
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedLoginForm = Form.create()(LoginForm);

export default WrappedLoginForm;
