import React from 'react';
import ContentLoader from "react-content-loader"

const BlankState = (props) => {
    let Loader;

    switch(props.type){
        case 'table':
            Loader = () => (
                    <ContentLoader
                        height={300}
                        width={900}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#dfdfdf"
                    >
                        <rect x="4.63" y="10.05" rx="0" ry="0" width="900" height="30" /> 
                        <rect x="4.63" y="57.05" rx="0" ry="0" width="900" height="30" /> 
                        <rect x="4.63" y="99.05" rx="0" ry="0" width="900" height="30" /> 
                        <rect x="4.63" y="139.05" rx="0" ry="0" width="900" height="30" /> 
                        <rect x="4.63" y="180.05" rx="0" ry="0" width="900" height="30" /> 
                        <rect x="4.63" y="220.05" rx="0" ry="0" width="900" height="30" />
                        <rect x="4.63" y="260.05" rx="0" ry="0" width="900" height="30" />
                    </ContentLoader>
                )
            break;
        case 'detail-view':
            const LoadingTitleBlock = () => (
                <ContentLoader
                    height={70}
                    width={900}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#dfdfdf"
                >
                    <rect x="4.63" y="20.05" rx="2" ry="2" width="350" height="35" />
                </ContentLoader>
            )                      

            const LoadingInfoBlock = () => (
                <ContentLoader
                    height={40}
                    width={900}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#dfdfdf"
                >
                    <rect x="18.63" y="5.05" rx="0" ry="0" width="700" height="30" />
                </ContentLoader>
            )  

            const LoadingButtonBlock = () => (
                <ContentLoader
                    height={40}
                    width={200}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#dfdfdf"
                >
                    <rect x="30" y="5.05" rx="0" ry="0" width="130" height="27" />
                </ContentLoader>
            )                           

            Loader = () => (
               <div className="content-panel detail-view">
                  <header className="shadow bg-pure-white">
                      <LoadingTitleBlock/>
                      <div className="row align-items-center">
                          <div className="col">
                              <LoadingInfoBlock />
                          </div>
                          <div className="col">
                              <LoadingInfoBlock />
                          </div>
                          <div className="col">
                              <LoadingButtonBlock />
                          </div>
                      </div>
                  </header>
              </div>
            )
            break;
        default:
            break;

    }
    return <Loader/>;
};

export default BlankState;