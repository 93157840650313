import React, { Component } from "react";
import WrappedLoginForm from "./LoginForm";

class LoginPage extends Component {
  render() {
    return (
      <section className="flex justify-content-center align-items-center login-page">
        <div className="shadow login-wrapper">
          <h2 className="text-center">Login</h2>
          <WrappedLoginForm />
        </div>
      </section>
    );
  }
}

export default LoginPage;
