import React, { Component } from "react";

import { Form, Button, Select } from "antd";
import axios from "axios";
import get from "lodash/get";
import startCase from "lodash/startCase";

import axiosClient from "utils/axios";
import ErrorParser from "utils/ErrorParser";
import FormStatus from "shared/FormStatus";

const FormItem = Form.Item;

const Option = Select.Option;

class RoleForm extends Component {
  constructor() {
    super();

    this.state = {
      formInfo: {},
      roles: [],
      loading: false
    };

    this.authListener = null;
    this.axiosHandler = axios.CancelToken.source();
  }

  componentDidMount() {
    axiosClient
      .get(`/role`, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        this.setState({ roles: get(response.data, "returned_resultset") });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  componentWillUnmount() {
    this.axiosHandler.cancel("Unmounting component");
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.setState({
        errorMessage: "",
        loading: true,
        formInfo: {}
      });

      axiosClient
        .put(`/user/${get(this.props.userDetail, "id")}`, values)
        .then(() => {
          this.setState({
            loading: false,
            formInfo: {
              message: "Role Updated Successfully",
              type: "success"
            }
          });

          let userDetail = {
            ...this.props.userDetail,
            role: {
              name: get(values, "role")
            }
          };

          this.props.updateUser(userDetail);
        })
        .catch(error => {
          this.setState({ loading: false, formInfo: ErrorParser(error) });
        });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { loading, formInfo, roles } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} className="role-form">
        <FormStatus formInfo={formInfo} loading={loading} />
        <FormItem>
          {getFieldDecorator("role", {
            initialValue: get(this.props.userDetail, "role.name"),
            rules: [{ required: true, message: "Please select a role" }]
          })(
            <Select style={{width: '32%'}} placeholder="Select a role">
              {roles.map((column, index) => (
                <Option key={index} value={get(column, "name")}>
                  {startCase(get(column, "name"))}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>
        <FormItem className="text-center">
          <Button type="primary" htmlType="submit" className="form-button">
            Assign Role
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedRoleForm = Form.create()(RoleForm);

export default WrappedRoleForm;
