import React, { Component } from "react";

import { Table, Input, Select } from "antd";
import get from "lodash/get";
import find from "lodash/find";
import filter from "lodash/filter";
import axios from "axios";

import { SURVEY_STAGE_KEY } from "utils/app-constants";
import localStorage from "utils/localStorage";
import PageTitle from "shared/PageTitle";
import axiosClient from "utils/axios";
import BlankState from "shared/BlankState";
import Auth from "shared/Auth";

const InputGroup = Input.Group;
const Option = Select.Option;

const OFFSET_LIMIT = 20;

let timeout;
let processQue = [];

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applicants: [],
      loading: false,
      emptyText: "",
      searchColumn: "email",
      displayColumn: "Email",
      filterOn: false,
      totalResultSize: 0,
      currentPageNumber: 1,
      columns: [
        {
          title: "#",
          dataIndex: "user_id",
          searchable: false,
          render: text => <a href="#23">{text}</a>
        },
        {
          title: "Email",
          searchable: true,
          dataIndex: "email"
        },
        {
          title: "Organisation",
          searchable: true,
          dataIndex: "organisation"
        },
        {
          title: "Name",
          searchable: true,
          dataIndex: "names"
        },
        {
          title: "Country",
          searchable: false,
          dataIndex: "country",
        },
        {
          title: "Current Stage",
          searchable: false,
          dataIndex: "current_stage_id"
        },
        {
          title: "Role",
          searchable: false,
          searchColumn: "role",
          dataIndex: "role.name",
          render: text => text || "N/A"
        }
      ],
      originalApplicants: []
    };

    this.axiosHandler = axios.CancelToken.source();
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.axiosHandler.cancel("Unmounting component");
  }

  loadData = (pageNumber = 1) => {
    let offset = (OFFSET_LIMIT * pageNumber) - OFFSET_LIMIT;

    this.setState({ applicants: [], currentPageNumber: pageNumber, emptyText: 'Loading Page...'});

    axiosClient
      .get(`/user?limit=20&offset=${offset}`, { cancelToken: this.axiosHandler.token })
      .then(response => {
        this.setState({
          applicants: this.parseData(
            get(response.data, "returned_resultset", [])
          ),
          originalApplicants: this.parseData(
            get(response.data, "returned_resultset", [])
          ),
          totalResultSize: get(response.data, "available_resultset_size", []),
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          emptyText: error
        });
      });
  };

  parseData = (applicants = []) => {
    let offset = (OFFSET_LIMIT * this.state.currentPageNumber) - OFFSET_LIMIT;

    return applicants.map((applicant, index) => {
      let surveyStageId = localStorage.getItem(SURVEY_STAGE_KEY);

      let stageId = get(applicant, "current_stage_id");

      let stageName = surveyStageId === stageId ? "Survey" : "Review";

      let country = find(Auth.getCountries(), [
        "Code",
        get(applicant, "country_code")
      ]);

      return {
        ...applicant,
        current_stage_id: stageName,
        user_id: index + 1 + offset,
        country: get(country, "Name")
      };
    });
  };

  reviseNumbering = (applicants = []) => {
    return applicants.map((applicant, index) => ({
      ...applicant,
      user_id: index + 1
    }));
  };

  setSearchColumn = value =>
    this.setState({
      searchColumn: get(
        find(this.state.columns, ["dataIndex", value]),
        "dataIndex"
      ),
      displayColumn: get(
        find(this.state.columns, ["dataIndex", value]),
        "title"
      )
    });

  filterTable = ev => {
    let value = ev.target.value;

    let lowerCasedValue = value && value.toLowerCase();
    
    let offset = (OFFSET_LIMIT * this.state.currentPageNumber) - OFFSET_LIMIT;

    // Clear existing timeout
    if (timeout) clearTimeout(timeout);

    const handleProcessing = () => {
      console.log(lowerCasedValue);
      this.setState({
        applicants: lowerCasedValue.length > 1
          ? []
          : this.state.originalApplicants,
        emptyText: 'Searching for data....',
        filterOn: lowerCasedValue.length ? true : false,
      });

      axiosClient
        .get(`/user?${this.state.searchColumn}=${lowerCasedValue}&limit=20&offset=${offset}`)
        .then((response) => {
          let usersFound = this.parseData(get(response.data, "returned_resultset", []));

          this.setState({
            applicants: this.reviseNumbering(usersFound),
            emptyText: !usersFound.length ? 'No applicants found' : '',
            loading: false
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            emptyText: error
          });
        });
    }

    processQue.push(handleProcessing);

    timeout = setTimeout(() => {
      processQue[processQue.length - 1]();
      processQue = [];
    }, 600);
  };

  render() {
    const {
      applicants,
      emptyText,
      loading,
      columns,
      displayColumn,
      filterOn,
      totalResultSize,
      currentPageNumber
    } = this.state;

    if (loading) return <BlankState type="table" />;

    return (
      <div>
        <PageTitle title="Applicant List" />
        <div className="text-right search-bar">
          <InputGroup compact>
            <Select
              style={{ width: "15%" }}
              onChange={this.setSearchColumn}
              defaultValue="email"
            >
              {columns.filter(i => i.searchable).map((column, index) => (
                <Option key={index} value={get(column, "dataIndex")}>
                  {get(column, "title")}
                </Option>
              ))}
            </Select>
            <Input
              style={{ width: "40%" }}
              onChange={this.filterTable}
              placeholder={`Search for applicant by ${displayColumn}`}
            />
          </InputGroup>
        </div>
        {filterOn && <h3>{applicants.length || "No"} applicants were found</h3>}
        <Table
          className="bg-pure-white shadow user-table"
          columns={columns}
          dataSource={applicants}
          locale={{
            emptyText,
          }}
          pagination={{
            defaultPageSize: 20,
            total: totalResultSize,
            onChange: this.loadData,
            defaultCurrent: currentPageNumber
          }}
          onRow={record => {
            return {
              onClick: () => {
                this.props.history.push(`/applicants/${get(record, "id")}`);
              }
            };
          }}
          rowKey="id"
        />
      </div>
    );
  }
}

export default Users;
