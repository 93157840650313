import get from "lodash/get";

import localStorage from "utils/localStorage";
import {
  PROFILE_KEY,
  COUNTRIES_KEY,
  SECTIONS_KEY,
  VERIFIABLE_FIELDS_KEY,
  SURVEY_STAGE_KEY,
  CDR_STAGES
} from "utils/app-constants";

import { AuthSubject } from "shared/AuthSubject";

class Auth {
  isAuthenticated() {
    return this.getUser() ? true : false;
  }

  getUser() {
    return get(localStorage.getList(PROFILE_KEY), "user");
  }

  getCountries() {
    return localStorage.getList(COUNTRIES_KEY);
  }

  getQuestions(cdrStageId) {
    return localStorage.getList(`${cdrStageId}_FORMS`);
  }

  getVerifiableFields() {
    return localStorage.getList(VERIFIABLE_FIELDS_KEY);
  }

  getToken() {
    return get(localStorage.getList(PROFILE_KEY), "access_token", "");
  }

  logout(formInfoMessage = {}) {
    localStorage.removeItem(PROFILE_KEY);
    localStorage.removeItem(COUNTRIES_KEY);
    localStorage.removeItem(SECTIONS_KEY);
    localStorage.removeItem(SURVEY_STAGE_KEY);
    localStorage.removeItem(VERIFIABLE_FIELDS_KEY);
    localStorage.removeItem(CDR_STAGES);

    AuthSubject.next({ profile: false, formInfo: formInfoMessage });
  }
}

export default new Auth();
