let DEV_BASE_URL = "http://localhost";
let STAGING_BASE_URL = "http://admin.staging.epicafrica.ihub.co.ke";
let PRODUCTION_BASE_URL = "https://admin.epic-africa.org";

let STAGING_BASE_API_URL = "http://api.staging.epicafrica.ihub.co.ke/api/v1";
let PRODUCTION_BASE_API_URL = "https://api.awards.epic-africa.org/api/v1";

let BASE_URL = "";
let BASE_API_URL = "";
let QUESTIONS_KEY = "__questions__";
let PROFILE_KEY = "__profile__";
let ANSWERS_KEY = "__answers__";
let LOCALE_KEY = "__lang__";
let COUNTRIES_KEY = "__countries__";
let SECTIONS_KEY = "__sections__";
let SURVEY_STAGE_KEY = "__survey_id__";
let VERIFIABLE_FIELDS_KEY = "__verifiable__";
let CDR_STAGES = "__cdr_stages__";

let location = window && window.location;

const hostname = `${location.protocol}//${location.hostname}`;

switch (hostname) {
  case STAGING_BASE_URL:
    BASE_URL = STAGING_BASE_URL;
    BASE_API_URL = STAGING_BASE_API_URL;
    console.log("Staging");
    break;

  case PRODUCTION_BASE_URL:
    BASE_URL = PRODUCTION_BASE_URL;
    BASE_API_URL = PRODUCTION_BASE_API_URL;
    console.log("Production");
    break;

  default:
    BASE_URL = `${DEV_BASE_URL}:${location.port}`;
    BASE_API_URL = STAGING_BASE_API_URL;
    console.log("Dev");
    break;
}

module.exports = {
  BASE_URL,
  BASE_API_URL,
  QUESTIONS_KEY,
  PROFILE_KEY,
  ANSWERS_KEY,
  LOCALE_KEY,
  COUNTRIES_KEY,
  SECTIONS_KEY,
  SURVEY_STAGE_KEY,
  VERIFIABLE_FIELDS_KEY,
  CDR_STAGES,
};
