import React, { Component } from "react";
import { Link, Switch, Route, Redirect} from "react-router-dom";

import PageNotFound from "shared/PageNotFound";
import PageTitle from "shared/PageTitle";
import Connectivity from "shared/Connectivity";
import epicLogo from "assets/epic-logo.jpeg";
import Login from '../Login/LoginPage';

class LandingPage extends Component {
  render() {
    return (
      <div className="landing-page">
        <PageTitle title="Welcome to the CSO Admin" />
        <Connectivity />
        <div className="flex align-items-center justify-content-center">
          <Link to="/" className="logo-container shadow">
            <img src={epicLogo} className="primary-logo" alt="Logo" />
          </Link>
        </div>
        <div className="container-fluid no-margin">
          <div className="main-content full-width">
            <Switch>
              <Route path="/login" exact component={Login} />
              <Redirect from="/" to="/login"/>
              <Route component={PageNotFound} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
