import React, { Component } from "react";

import get from "lodash/get";
import find from "lodash/find";
import axios from "axios";

import axiosClient from "utils/axios";
import FieldProcessor from "./FieldProcessor";
import Auth from "shared/Auth";
import { ReloadPageSubject } from "shared/ReloadPageSubject";

class Responses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: [],
      responses: [],
      activeSection: [],
      loading: true,
      sectionHeading: []
    };

    this.axiosHandler = axios.CancelToken.source();
    this.reloadPageListener = null;
  }

  componentDidMount() {
    this.reloadPageListener = ReloadPageSubject.subscribe(payload => {
      if (get(payload, "page") === "applicant-responses") this.fetchAnswers();
    });

    if (this.props.userDetail) {
      this.fetchAnswers();
    }
  }

  componentWillUnmount() {
    if (this.reloadPageListener !== null) {
      this.reloadPageListener.unsubscribe();
      this.reloadPageListener = null;
    }

    this.axiosHandler.cancel("Unmounting component");
  }

  loadSection = sectionId => {
    let section = find(this.state.sections, ["id", sectionId]);

    this.setState({
      activeSection: get(section, "questions"),
      sectionHeading: get(section, this.props.titleKey)
    });
  };

  fetchAnswers = () => {
    this.setState({ loading: true });

    axiosClient
      .get(`/response?user_id=${get(this.props.userDetail, "id")}`, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        const rawResponses = get(response.data, "returned_resultset");

        const processedResponses = {};

        rawResponses.forEach(rawResponse => {
          let finalResponse;

          try {
            finalResponse = JSON.parse(get(rawResponse, "response"));
          } catch (e) {
            finalResponse = get(rawResponse, "response");
          }

          processedResponses[get(rawResponse, "question_id")] = finalResponse;
        });

        this.setState({
          responses: processedResponses,
          sections: Auth.getQuestions(this.props.cdrStageId),
          activeSection: get(Auth.getQuestions(this.props.cdrStageId)[0], "questions"),
          loading: false,
          sectionHeading: get(Auth.getQuestions(this.props.cdrStageId)[0], this.props.titleKey)
        });
      })
      .catch(() => {
        this.setState({
          loading: false
        });
      });
  };

  processQuestion = (questionDef, index, parentLink = false) => {
    const questions = get(questionDef, "questions");

    const baseQuestion =
      get(questionDef, "question_type") === "multiple_questions" ? (
        <div key={`${index}-${get(questionDef, this.props.titleKey)}`}>
          {parentLink ? (
            <p className="question-title primary-blue">
              {" "}
              {get(questionDef, this.props.titleKey)}
            </p>
          ) : (
            <li
              className={`question-list__item__question ${get(
                questionDef,
                "question_type"
              )}`}
            >
              <p>
                {" "}
                {get(questionDef, this.props.titleKey)}
                <span className="primary-orange">
                  {get(questionDef, "is_mandatory") ? "*" : null}
                </span>
              </p>
            </li>
          )}
          <ol className={`question-list ${get(questionDef, "question_type")}`}>
            {questions.map((questionItem, qnIndex) =>
              this.processQuestion(questionItem, qnIndex)
            )}
          </ol>
        </div>
      ) : (
        <li
          className="question-list__item"
          key={`${index}-${get(questionDef, this.props.titleKey)}`}
        >
          <div className="question-list__item__question">
            <p>
              {get(questionDef, this.props.titleKey)}
              <span className="primary-orange">
                {get(questionDef, "is_mandatory") ? "*" : null}
              </span>
            </p>
          </div>
          {questions && questions.length ? (
            questions.map((questionItem, qnIndex) =>
              this.processQuestion(questionItem, qnIndex)
            )
          ) : (
            <FieldProcessor
              titleKey={this.props.titleKey}
              valueKey={this.props.valueKey}
              responses={this.state.responses}
              question={questionDef}
            />
          )}
        </li>
      );

    return baseQuestion;
  };

  render() {
    if (this.state.loading) {
      return (
        <p className="pre-loader sub-head text-center">Loading Responses...</p>
      );
    }

    let questionFields = this.state.activeSection.map((questionDef, index) =>
      this.processQuestion(questionDef, index, true)
    );

    let sections = this.state.sections.map((section, index) => (
      <li
        key={index}
        className="navigation-item"
        onClick={() => this.loadSection(get(section, "id"))}
      >
        <span
          className={`navigation-item__link ${
            this.state.sectionHeading === get(section, this.props.titleKey)
              ? "active"
              : null
          }`}
        >
          {get(section, this.props.titleKey)}
        </span>
      </li>
    ));

    return (
      <section className="container-fluid responses-container">
        <div className="row">
          <div className="col-3 response-sidebar">
            <ul>{sections}</ul>
          </div>
          <div className="col-9 questions-container">
            <ol className="question-list">{questionFields}</ol>
          </div>
        </div>
      </section>
    );
  }
}

export default Responses;
