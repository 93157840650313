import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

export default (error) => {
  let message = get(error, 'response.data.message', {}) || get(error, 'response.statusText');

  // Incase the message has objects
  if (typeof(message) !== "string") {
    let messages = [];

    Object.keys(message).forEach(key => {
      messages.push(capitalize(get(message, key)));
    });

    message = messages.join(" , ");
  }

  if (!message) {
    message = 'Unable to complete your request. Please try again after a few minutes';
  }

  return {
    message,
    type: 'error'
  };
};
