import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.css';
import BaseLayout from 'components/Layout/BaseLayout';

// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<BaseLayout />, document.getElementById('root'));

// registerServiceWorker();
