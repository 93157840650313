import React from 'react';

const PageNotFound = () => {
    return (
        <div className="text-center">
            <h1 className="heading">404?</h1>
            <p className="explanation">This page doesn't exist</p>
        </div>
    );
};

export default PageNotFound;