import React, { Component } from "react";
import { Route, Switch, Link, Redirect, NavLink } from "react-router-dom";

import { Icon } from "antd";
import get from "lodash/get";

import { CDR_STAGES } from "utils/app-constants";
import PageTitle from "shared/PageTitle";
import PageNotFound from "shared/PageNotFound";
import Connectivity from "shared/Connectivity";
import epicLogo from "assets/epic-logo.jpeg";
import Users from "../Users";
import CDRs from "../CDRs";
import Detail from "../Users/Detail";
import Auth from "shared/Auth";
import localStorage from "utils/localStorage";

class ApplicationLayout extends Component {
  render() {
    const cdrMenus = localStorage.getList(CDR_STAGES).map(cdrStage => (
      <li key={get(cdrStage, 'id')}>
        <NavLink to={`/cdrs/${get(cdrStage, 'id')}`} className="sidebar-link small">
          {get(cdrStage, 'title')}
        </NavLink>
      </li>
    ))

    return (
      <div className="container-fluid">
        <PageTitle title="CSO Dashboard" />
        <Connectivity />
        <section className="row dashboard-content">
          <div className="col-2 bg-primary-blue sidebar shadow">
            <Link to="/" className="logo-container">
              <img src={epicLogo} className="primary-logo" alt="Logo" />
            </Link>
            <ul>
              <li>
                <NavLink to="/applicants" className="sidebar-link small">
                  Applicants
                </NavLink>
              </li>
              {cdrMenus}
            </ul>
            <div className="text-left footer-control">
              <Icon
                type="export"
                style={{ color: "rgba(255, 255, 255, 0.85)" }}
              />
              <span
                onClick={Auth.logout}
                className="small logout-btn pure-white"
              >
                Logout
              </span>
            </div>
          </div>
          <div className="col-10 main-content">
            <header className="toolbar" />
            <Switch>
              <Route path="/applicants" exact component={Users} />
              <Route path="/applicants/:id" exact component={Detail} />
              <Route path="/cdrs/:id" exact component={CDRs} />
              <Redirect from="/" to="/applicants" />
              <Route component={PageNotFound} />
            </Switch>
          </div>
        </section>
      </div>
    );
  }
}

export default ApplicationLayout;
