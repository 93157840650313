import React, { Component } from "react";

import { Table, Input, Select, Button, Tabs } from "antd";
import get from "lodash/get";
import find from "lodash/find";
import filter from "lodash/filter";
import axios from "axios";

import PageTitle from "shared/PageTitle";
import axiosClient from "utils/axios";
import BlankState from "shared/BlankState";
import Auth from "shared/Auth";
import ErrorParser from "utils/ErrorParser";
import FormStatus from "shared/FormStatus";

const InputGroup = Input.Group;
const Option = Select.Option;
const TabPane = Tabs.TabPane;

const OFFSET_LIMIT = 45;

class CDRs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applicantCdrs: [],
      loading: false,
      sections: [],
      formInfo: {},
      emptyText: "",
      searchColumn: "email",
      displayColumn: "Email",
      currentStageId: '',
      filterOn: false,
      totalApplicantResultSize: 0,
      totalShortlistResultSize: 0,
      currentCandidatePageNumber: 1,
      currentShortlistPageNumber: 1,
      currentPageNumber: 1,
      originalApplicantCdrs: [],
      selectedRowKeys: [],
      shortlistedCSOs: [],
      shortlistedColumns: [
        {
          title: "#",
          width: 60,
          render: (record) => <span style={{ color: '#03A9F4' }} onClick={() => this.props.history.push(`/applicants/${get(record, "id")}`)}>{get(record, 'user_id')}</span>
        },
        {
          title: "Email",
          width: 100,
          render: (record) => <span onClick={() => this.props.history.push(`/applicants/${get(record, "id")}`)}>{get(record, 'email')}</span>
        },
        {
          title: "Organisation",
          dataIndex: "organisation",
          width: 100,
        },
        {
          title: "Name",
          dataIndex: "names",
          width: 100,
        },
        {
          title: "Country",
          dataIndex: "country",
          width: 100,
        },
        {
          title: "Total",
          key: 'points',
          width: 50,
          dataIndex: "points"
        },
        {
          title: "Action",
          key: 'action',
          width: 100,
          render: (record) => (
            <div>
              <Button
                style={{marginRight: '1rem'}}
                title="Send Email Notification"
                onClick={() => this.sendNotification(get(record, 'id'), get(record, 'email'))}
                icon="mail"
                shape="circle-outline"
              />
              <Button
                title="Remove from shortlist"
                onClick={() => this.removeFromShortlist(get(record, 'id'))}
                icon="close"
                shape="circle-outline"
              />
            </div>
          )
        }
      ],
      columns: [],
      originalShortlistedCSOs: [],
      defaultActiveKey: 'applicants',
      filterOptions: {
        activeColumns: [],
        activeFullPayloadKey: 'originalApplicantCdrs',
        activeResultKey: 'applicantCdrs'
      }
    };

    this.axiosHandler = axios.CancelToken.source();
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps){
    let currentStageId = get(nextProps, 'match.params.id');

    this.setState({
      currentStageId
    })

    this.loadData(currentStageId);
  }

  componentWillUnmount() {
    this.axiosHandler.cancel("Unmounting component");
  }

  loadData = (stageId = '') => {
    this.setState({ loading: true});

    axiosClient
      .get(`/stage/${stageId || get(this.props.match.params, 'id')}`, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        let sections = get(response.data, "returned_resultset[0].forms", []);

        let offset = (OFFSET_LIMIT * 1) - OFFSET_LIMIT;

        let applicants = axiosClient
          .get(`/stage/${stageId || get(this.props.match.params, 'id')}/cdr?offset=${offset}`, {
            cancelToken: this.axiosHandler.token
          })
          .then(response => {
            return {
              applicants: get(response.data, "returned_resultset", []),
              totalApplicantResultSize: get(response.data, "available_resultset_size", []),
              sections
            }
          })

        const sectionColumns = sections.map((section, index) => ({
          title: get(section, 'title'),
          dataIndex: get(section, 'id'),
          key: get(section, 'id'),
          width: 150,
          render: text => <span>{text || '0'}</span>
        }));

        const columns = [
          {
            title: "#",
            fixed: 'left',
            width: 60,
            render: (record) => <span style={{ color: '#03A9F4'}} onClick={() => this.props.history.push(`/applicants/${get(record, "id")}`)}>{get(record, 'user_id')}</span>
          },
          {
            title: "Email",
            width: 250,
            fixed: 'left',
            key: 'email',
            render: (record) => <span onClick={() => this.props.history.push(`/applicants/${get(record, "id")}`)}>{get(record, 'email')}</span>
          },
          ...sectionColumns,
          {
            title: "Total",
            fixed: 'right',
            key: 'total',
            width: 100,
            dataIndex: "totals"
          },
          {
            title: "Action",
            fixed: 'right',
            key: 'action',
            dataIndex: 'id',
            width: 100,
            render: (text) => (
              <Button
                title="Shortlist"
                onClick={() => this.shortlistCandidate(text)}
                icon="check"
                shape="circle-outline"
              />
            )
          },
        ];          

        Promise.all([applicants])
              .then((responses) => {
                this.setState({
                  sections: get(responses[0], 'sections'),
                  applicantCdrs: this.parseData(
                    get(responses[0], 'applicants')
                  ),
                  originalApplicantCdrs: this.parseData(
                    get(responses[0], 'applicants')
                  ),
                  loading: false,
                  totalApplicantResultSize: get(responses[0], 'totalApplicantResultSize'),
                  columns,
                  filterOptions: {
                    ...this.state.filterOptions,
                    activeColumn: columns,
                  }
                });
              })
              .catch(error => {
                this.setState({
                  loading: false,
                  formInfo: ErrorParser(error),
                  emptyText: error
                });
              });                  
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleCandidatePagination = (pageNumber) => {
    let offset = (OFFSET_LIMIT * pageNumber) - OFFSET_LIMIT;

    this.setState({ loading: true, currentCandidatePageNumber: pageNumber });
    
    axiosClient
      .get(`/stage/${this.state.currentStageId || get(this.props.match.params, 'id')}/cdr?limit=45&offset=${offset}`, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        let shorlist = get(response.data, "returned_resultset", []);

        this.setState({
          applicantCdrs: this.parseData(
            shorlist
          ),
          originalApplicantCdrs: this.parseData(
            shorlist
          ),
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          emptyText: error,
          formInfo: ErrorParser(error),
        });
      });               
  }

  loadShortlistedCSOs = (pageNumber = 1) => {
    let offset = (OFFSET_LIMIT * pageNumber) - OFFSET_LIMIT;

    this.setState({ loading: true, currentShorlistPageNumber: pageNumber});

    axiosClient
      .get(`/stage/${this.state.currentStageId || get(this.props.match.params, 'id')}/shortlist?limit=45&offset=${offset}`, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        let shorlist =  get(response.data, "returned_resultset", []);

        this.setState({
          shortlistedCSOs: this.parseShorlistData(
            shorlist
          ),
          originalShortlistedCSOs: this.parseShorlistData(
            shorlist
          ),
          totalShortlistResultSize: get(response.data, "available_resultset_size", []),
          loading: false
        });        
      })
      .catch(error => {
        this.setState({
          loading: false,
          formInfo: ErrorParser(error),
          emptyText: error
        });
      });      
  }
  
  shortlistCandidate = (user_id) => {
    this.setState({ loading: true });

    let payload = {
      user_id
    }

    axiosClient
      .post(`/stage/${this.state.currentStageId || get(this.props.match.params, 'id')}/shortlist`, payload, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        this.setActiveKey('shortlisted');
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({
          loading: false,
          formInfo: ErrorParser(error),
          emptyText: error
        });
      });      
  }

  sendNotification = (user_id, email) => {
    this.setState({ loading: true });

    let payload = {
      user_id
    }

    axiosClient
      .post(`/stage/${this.state.currentStageId || get(this.props.match.params, 'id')}/notification`, payload, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        this.setState({
          loading: false,
          formInfo: {
            message: `${get(response, 'data.message')} to ${email}`,
            type: 'success'
          }
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          formInfo: ErrorParser(error),
          emptyText: error
        });
      });    
  }

  removeFromShortlist = (user_id) => {
    this.setState({ loading: true });

    axiosClient
      .delete(`/stage/${this.state.currentStageId || get(this.props.match.params, 'id')}/shortlist/${user_id}`, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        this.setActiveKey('shortlisted');
      })
      .catch(error => {
        this.setState({
          loading: false,
          formInfo: ErrorParser(error),
          emptyText: error
        });
      });
  }

  parseData = (applicantCdrs = []) => {
    return applicantCdrs.map((applicantCdr, index) => {
      let userInfo = get(applicantCdr, 'user');
      let userData = get(applicantCdr, 'user_data');

      let payload = {
        user_id: index + 1,
        id: get(userInfo, 'id'),
        email: get(userInfo, 'email'),
      };

      let totals = 0;
      
      userData.forEach((formSection) => {
        payload[get(formSection, 'form_id')] = get(formSection, 'points') || 0;
        
        totals += Number(get(formSection, 'points', 0));
      })

      payload['totals'] = totals;

      return payload
    });
  };

  parseShorlistData = (shortlisted = []) => {
    return shortlisted.map((applicantInfo, index) => {
      let applicant = get(applicantInfo, 'user');

      let country = find(Auth.getCountries(), [
        "Code",
        get(applicant, "country_code")
      ]);

      return {
        ...applicant,
        points: get(applicantInfo, 'points'),
        user_id: index + 1,
        country: get(country, "Name")
      };
    });
  };  

  reviseNumbering = (applicantCdrs = []) => {
    return applicantCdrs.map((applicantCdr, index) => ({
      ...applicantCdr,
      user_id: index + 1
    }));
  };

  setSearchColumn = value =>
    this.setState({
      searchColumn: get(
        find(get(this.state.filterOptions, 'activeColumn', []), ["dataIndex", value]),
        "dataIndex"
      ),
      displayColumn: get(
        find(get(this.state.filterOptions, 'activeColumn', []), ["dataIndex", value]),
        "title"
      )
    });

  filterTable = ev => {
    let value = ev.target.value;

    let lowerCasedValue = value && value.toLowerCase();

    let sanitisedValue = lowerCasedValue.replace(
      /(\+)|(@)|(-)/g,
      v => "\\" + v
    );

    let re = new RegExp(sanitisedValue);

    let filterList = filter(this.state[get(this.state.filterOptions, 'activeFullPayloadKey')], obj =>
      re.test(`${get(obj, this.state.searchColumn)}`.toLowerCase())
    );

    this.setState({
      [get(this.state.filterOptions, 'activeResultKey')]:
        value.length > 1
          ? this.reviseNumbering(filterList)
          : this.state[get(this.state.filterOptions, 'activeFullPayloadKey')],
      filterOn: value.length ? true : false
    });
  };

  shortlistSelected = () => {
    console.log(this.state.selectedRowKeys);
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  setActiveKey = (defaultActiveKey) => {
    switch (defaultActiveKey) {
      case 'applicants':
        this.handleCandidatePagination(this.state.currentCandidatePageNumber);

        this.setState({
          filterOptions: {
            activeColumn: this.state.columns,
            activeFullPayloadKey: 'originalApplicantCdrs',
            activeResultKey: 'applicantCdrs'
          },
          formInfo: {},
          filterOn: false
        })
        break;
      case 'shortlisted':
        this.loadShortlistedCSOs();

        this.setState({
          filterOptions: {
            activeColumn: this.state.shorlistedColumns,
            activeFullPayloadKey: 'originalShortlistedCSOs',
            activeResultKey: 'shortlistedCSOs'
          },
          formInfo: {},
          filterOn: false
        })
        break;    
      default:
        break;
    }
    this.setState({ defaultActiveKey })
  };

  render() {
    const {
      applicantCdrs,
      emptyText,
      loading,
      displayColumn,
      filterOn,
      columns,
      selectedRowKeys,
      shortlistedCSOs,
      defaultActiveKey,
      currentCandidatePageNumber,
      currentShorlistPageNumber,
      totalApplicantResultSize,
      totalShortlistResultSize,
      filterOptions,
      formInfo,
      shortlistedColumns
    } = this.state;

    if (loading) return <BlankState type="table" />;
    
    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectChange,
    //   hideDefaultSelections: true,
    // };

    return (
      <div>
        <PageTitle title="Applicant List" />
        <div className="text-right search-bar">
          <InputGroup compact>
            <Select
              style={{ width: "15%" }}
              onChange={this.setSearchColumn}
              defaultValue="email"
            >
              {get(filterOptions, 'activeColumn', []).map((column, index) => (
                <Option key={index} value={get(column, "dataIndex")}>
                  {get(column, "title")}
                </Option>
              ))}
            </Select>
            <Input
              style={{ width: "40%" }}
              onChange={this.filterTable}
              placeholder={`Search for applicant by ${displayColumn}`}
            />
          </InputGroup>
        </div>
        {filterOn && (
          <h3>{this.state[get(filterOptions, 'activeResultKey')].length || "No"} applicants were found</h3>
        )}
        <Tabs defaultActiveKey={defaultActiveKey} onChange={this.setActiveKey}>
          <TabPane tab="Candidates" key="applicants">
            <div className="table-action-bar">
              {/* <Button
                type="primary"
                onClick={this.shortlistSelected}
                disabled={!selectedRowKeys.length}
                loading={loading}
              >
                Shortlist Candidates
              </Button>         */}
              <FormStatus formInfo={formInfo} loading={loading} />
            </div>
            <Table
              className="bg-pure-white shadow user-table"
              columns={columns}
              dataSource={applicantCdrs}
              emptyText={emptyText}
              pagination={{
                defaultPageSize: 45,
                total: totalApplicantResultSize,
                onChange: this.handleCandidatePagination,
                defaultCurrent: currentCandidatePageNumber                
              }}
              size="middle"
              scroll={{ x: '230%', y: 650 }}
              rowKey="id"
            />
          </TabPane>
          <TabPane tab="Shortlisted" key="shortlisted">
            <div className="table-action-bar">
              {/* <Button
                type="primary"
                onClick={this.shortlistSelected}
                disabled={!selectedRowKeys.length}
                loading={loading}
              >
                Shortlist Candidates
              </Button>         */}
              <FormStatus formInfo={formInfo} loading={loading} />
            </div>            
            <Table
              className="bg-pure-white shadow user-table"
              columns={shortlistedColumns}
              dataSource={shortlistedCSOs}
              emptyText={emptyText}
              pagination={{
                defaultPageSize: 45,
                total: totalShortlistResultSize,
                onChange: this.loadShortlistedCSOs,
                defaultCurrent: currentShorlistPageNumber           
              }}
              scroll={{ y: 650 }}
              size="middle"
              rowKey="id"
            />
          </TabPane>
        </Tabs>        
      </div>
    );
  }
}

export default CDRs;
