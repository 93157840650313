import React, { Component } from "react";
import WrappedRoleForm from './RoleForm';

class Roles extends Component {
  render() {
    return (
      <div className="cdr-banner">
        <WrappedRoleForm {...this.props}/>
      </div>
    );
  }
}

export default Roles;
