import React, { Component } from "react";

import { Input, Checkbox } from "antd";
import get from "lodash/get";
import find from "lodash/find";
import axios from "axios";

import Auth from "shared/Auth";
import axiosClient from "utils/axios";
import ErrorParser from "utils/ErrorParser";
import FormStatus from "shared/FormStatus";

class Verification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: [],
      responses: [],
      questions: [],
      verifiedResponses: [],
      loading: true,
      formLoading: false,
      formInfo: {}
    };

    this.timeout = null;
    this.processQue = [];
    this.axiosHandler = axios.CancelToken.source();
  }

  componentDidMount() {
    if (this.props.userDetail) {
      this.fetchAnswers();
    }
  }

  componentWillUnmount() {
    this.axiosHandler.cancel("Unmounting component");
  }

  fetchAnswers = () => {
    this.setState({ loading: true });

    let responses = axiosClient
      .get(`/response?user_id=${get(this.props.userDetail, "id")}`, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        const rawResponses = get(response.data, "returned_resultset");

        const processedResponses = {};

        rawResponses.forEach(rawResponse => {
          let finalResponse;

          try {
            finalResponse = JSON.parse(get(rawResponse, "response"));
          } catch (e) {
            finalResponse = get(rawResponse, "response");
          }

          processedResponses[get(rawResponse, "question_id")] = finalResponse;
        });

        let questions = [];

        Auth.getQuestions().forEach(form => {
          questions = [...questions, ...get(form, "questions")];
        });

        return {
          responses: Object.keys(processedResponses).map(
            key => processedResponses[key]
          ),
          questions,
          sections: Auth.getVerifiableFields()
        };
      });

    let verfiedResponses = axiosClient
      .get(`/user/${get(this.props.userDetail, "id")}/field/status`, {
        cancelToken: this.axiosHandler.token
      })
      .then(response => {
        return get(response.data, "returned_resultset");
      });

    Promise.all([responses, verfiedResponses])
      .then(responses => {
        this.setState({
          ...responses[0],
          verifiedResponses: responses[1],
          loading: false
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  updateStatus = (points, fieldId, questionId, status) => {
    let payload = {
      points,
      question_id: questionId,
      status
    };

    this.setState({ formLoading: true, formInfo: {} });

    axiosClient
      .post(
        `/user/${get(this.props.userDetail, "id")}/field/${fieldId}/status`,
        payload
      )
      .then(() => {
        axiosClient
          .get(`/user/${get(this.props.userDetail, "id")}/field/status`, {
            cancelToken: this.axiosHandler.token
          })
          .then(response => {
            this.setState({
              verifiedResponses: get(response.data, "returned_resultset"),
              formLoading: false,
              formInfo: {
                message: "Field updated successfully",
                type: "success"
              }
            });
          })
          .catch(error => {
            this.setState({ formLoading: false, formInfo: ErrorParser(error) });
          });
      })
      .catch(error => {
        this.setState({ formLoading: false, formInfo: ErrorParser(error) });
      });
  };

  verifyField = (ev, points, fieldId, questionId) => {
    let status = ev.target.checked ? "VALID" : "INVALID";

    this.updateStatus(points, fieldId, questionId, status);
  };

  initiateUpdate = (ev, fieldId, questionId, status) => {
    let points = ev.target.value;

    if (!points) return;

    if (this.timeout) clearTimeout(this.timeout);

    this.processQue.push(this.updateStatus);

    this.timeout = setTimeout(() => {
      this.processQue[this.processQue.length - 1](
        points,
        fieldId,
        questionId,
        status
      );
      this.processQue = [];
    }, 500);
  };

  processField = (field, index) => {
    const fieldId = get(field, "field_id");
    const questionId = get(field, "question_id");

    const questionTitle = find(this.state.questions, ["id", questionId]);

    const verifiedStatus =
      find(this.state.verifiedResponses, ["field_id", fieldId]) || {};

    const points = get(field, "points");

    let answer = null;

    this.state.responses.forEach(response => {
      let key = Object.keys(response)[0];

      if (key === fieldId) {
        answer = response[key];
      }
    });
    
    return (
      <form key={index} className="verification-field flex align-items-start">
        <p className="col-4 question-list__item__question">
          {get(questionTitle, "title") || get(field, "title")}
        </p>
        <div className="col-2 input-file-upload">
          {answer ? (
            <a
              href={answer}
              className={`file-link`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="field-answer">View File</i>
            </a>
          ) : (
            <i className="field-answer">N/A</i>
          )}
        </div>
        <div className="col-2">
          <Input
            defaultValue={points}
            type="number"
            disabled={true}
            onChange={ev =>
              this.initiateUpdate(
                ev,
                fieldId,
                questionId,
                get(verifiedStatus, "status", "invalid")
              )
            }
          />
        </div>
        <div className="col-2">
          <Input
            defaultValue={
              Object.keys(verifiedStatus).length
                ? get(verifiedStatus, "points")
                : points
            }
            placeholder="Assign Points"
            type="number"
            onChange={ev =>
              this.initiateUpdate(
                ev,
                fieldId,
                questionId,
                get(verifiedStatus, "status", "invalid")
              )
            }
          />
        </div>
        <div className="col-2">
          <Checkbox
            checked={
              get(verifiedStatus, "status", "").toLocaleLowerCase() === "valid"
                ? true
                : false
            }
            onChange={ev =>
              this.verifyField(ev, get(field, "points"), fieldId, questionId)
            }
          />
        </div>
      </form>
    );
  };

  render() {
    const { loading, sections, formInfo, formLoading } = this.state;

    if (loading) {
      return (
        <p className="pre-loader sub-head text-center">
          Loading Verifiable Fields...
        </p>
      );
    }

    let questionFields = sections.map((questionDef, index) =>
      this.processField(questionDef, index)
    );

    return (
      <section className="container-fluid responses-container">
        <div className="row verification-table">
          <div className="col-12">
            <FormStatus formInfo={formInfo} loading={formLoading} />
          </div>
          <div className="col-12 flex table-header">
            <span className="small uppercase bold col-4">Field</span>
            <span className="small uppercase bold col-2">Response</span>
            <span className="small uppercase bold col-2">Question Points</span>
            <span className="small uppercase bold col-2">Received Points</span>
            <span className="small uppercase bold col-2">Verified</span>
          </div>
          <div className="col-12">{questionFields}</div>
        </div>
      </section>
    );
  }
}

export default Verification;
