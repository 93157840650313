import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";

import ApplicationLayout from "components/Layout/ApplicationLayout";
import LandingPage from "components/Layout/LandingPage";
import { AuthSubject } from "shared/AuthSubject";
import Auth from "shared/Auth";

class BaseLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: Auth.isAuthenticated()
    };

    this.authListener = null;
    this.layoutListener = null;
  }

  componentDidMount() {
    this.authListener = AuthSubject.subscribe(newState => {
      this.setState(newState);
    });
  }

  componentWillUnmount() {
    if (this.authListener !== null) {
      this.authListener.unsubscribe();
      this.authListener = null;
    }
  }

  render() {
    let activeLayout;

    if (this.state.profile) {
      activeLayout = <ApplicationLayout />;
    } else {
      activeLayout = <LandingPage />;
    }

    return (
      <BrowserRouter>
        <div>{activeLayout}</div>
      </BrowserRouter>
    );
  }
}

export default BaseLayout;
